import React from "react"
import loadable from "@loadable/component"
import tw, { styled } from "twin.macro"
import { P, H2 } from "./page-elements"
import Icon from "./icon"
import { SectionSummary } from "./shared/section-summary"
import { slideUp, slideLeft, slideRight } from "./shared/keyframes"
import { VisibleElement } from "./shared/visible-element"
import { SlideUp } from "./shared/SlideUp"
import ParralaxBackgroundSection from "./styles/parralax-background-section"
const Section = loadable(() => import("./shared/section"))

interface Testimonial {
  text: string
  src: string
  alt: string
  name: string
  role: string
}

const hash = ["first", "second", "third"]
const Content = {
  title: "What Customers Are Saying",
  testimonials: [
    {
      text: `"We were so incredibly pleased with the work done by Glacier! They cared for my home as if it was their own.  The results were phenomenal!  Not only does the house look great, but the trim work is protected.  We certainly appreciated the way they cleaned up at  the end of each day."`,
      src: "women-red.svg",
      alt: "Woman Icon",
      name: "Nancy K.",
      role: "Customer",
    },
    {
      text: `We were extremely pleased at the care they took.  The finished job is neat and even, despite the fact that the siding had faded irregularly over the prior years.  Our landscaping was carefully protected, as were windows & trim.  The final result is beautiful and price was outstanding.

We'll definitely be calling them again.`,
      src: "women-red.svg",
      alt: "Woman In Red Icon",
      name: "Carrie D.",
      role: "Customer",
    },
    {
      text: `Glacier Painting has done work for us twice now.  He is so knowledgable, and his work is outstanding.  Not only that, but he is a pleasure to work with - prompt, honest and responsible, responsive to questions, with competitive pricing.  We would highly recommend Glacier Painting!`,
      src: "woman-blue.svg",
      alt: "Woman In Blue Icon",
      name: "Katie M.",
      role: "Customer",
    },
  ],
}

const TestimonialSection = () => {
  return (
    <ParralaxBackgroundSection src="house2.jpg" alt="test">
      {/* <Section background="base-color" size="xl"> */}
      <div>
        <SlideUp>
          <H2 color="light" className="text-center">
            {Content.title}
          </H2>
        </SlideUp>
        <Testimonials>
          {Content.testimonials.map(
            ({ text, src, alt, name, role }: Testimonial, i) => {
              return (
                <VisibleElement offset={500}>
                  {({ visible }: { visible: boolean }) => {
                    return (
                      <Block
                        className={`${hash[i]} ${visible ? "visible" : ""}`}
                      >
                        <Quote color="light">{text}</Quote>
                        <Customer>
                          <AvatarContainer>
                            <AvatarImage
                              src={src}
                              alt={alt}
                              height="64"
                              width="64"
                            />
                          </AvatarContainer>
                          <AvatarDetails>
                            <P color="light">{name}</P>
                            <P color="light" className="text-sm">
                              {role}
                            </P>
                          </AvatarDetails>
                        </Customer>
                      </Block>
                    )
                  }}
                </VisibleElement>
              )
            }
          )}
        </Testimonials>
      </div>
      {/* </Section> */}
    </ParralaxBackgroundSection>
  )
}

const Testimonials = tw.div`
  flex
  flex-col
  md:flex-row
  flex-wrap
  w-full
  h-full
  max-w-screen-xl
  mx-auto
  overflow-hidden
`

const Block = styled.div`
  ${tw`
    flex
    flex-col
    flex-none
    relative
    w-full
    overflow-hidden
    mx-auto
    
    px-4
    md:px-16
    my-6
    md:my-12
    md:m-2
    md:my-2
    md:mx-4
    md:px-8
    md:flex-1
  `};
  opacity: 0;
  &.visible {
    &.first {
      animation-name: ${slideRight};
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
      animation-timing-function: ease-in-out;
    }
    &.second {
      animation-name: ${slideUp};
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
      animation-timing-function: ease-in-out;
    }
    &.third {
      animation-name: ${slideLeft};
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
      animation-timing-function: ease-in-out;
    }
  }
`

const Quote = styled(P)`
  ${tw`
    text-xl
    text-center
    md:text-left
  `}
`

const Customer = tw.div`
  flex
  mt-6
`

const AvatarDetails = tw.div`
  flex
  flex-col
  content-center	
  self-center
`

const Name = tw.div`

  

`
const Role = tw.div`
  text-sm
`

const AvatarContainer = tw.div`
  h-16
  w-16
  rounded-full
  overflow-hidden
  mr-4
`

const AvatarImage = styled(Icon)`
  ${tw`
  w-full
`}
`

export default TestimonialSection
